/**
* Top page - Works section Carousel
*/

const splide = () => {
  //@ts-ignore
  new Splide('.js-works-top-carousel', {
    mediaQuery: 'min',
    breakpoints: {
      901: {
        destroy: true,
      },
    },
    pagination: false,
    type   : 'loop',
  }).mount()
}

export default splide